// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  KyberNetwork,
  Messages2,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  Briefcase,
  Like1,
  Link,
  MessageFavorite,
  People,
  Barcode,
  GridLock,
  Teacher,
} from "iconsax-react";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  meetups: People,
  connections: Link,
  appreciation: Like1,
  business: Briefcase,
  testimonials: MessageFavorite,
  reports:Barcode,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  growthadmin:GridLock,
  trainings:Teacher,
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
  id: "group-applications",
  title: <FormattedMessage id="Options" />,
  icon: icons.applications,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.customer,
      breadcrumbs: false,
    },
    {
      id: "meetups",
      title: <FormattedMessage id="Meetups" />,
      type: "item",
      url: "/meetups",
      icon: icons.meetups,
      breadcrumbs: false,
    },
    {
      id: "connections",
      title: <FormattedMessage id="Connections" />,
      type: "item",
      url: "/connections",
      icon: icons.connections,
      breadcrumbs: false,
    },
    {
      id: "business",
      title: <FormattedMessage id="Business Received" />,
      type: "item",
      url: "/business",
      icon: icons.business,
      breadcrumbs: false,
      state: {
        type: "given",
      },
    },
    {
      id: "appreciations",
      title: <FormattedMessage id="Appreciations" />,
      type: "item",
      url: "/appreciations",
      icon: icons.appreciation,
      breadcrumbs: false,
      state: {
        type: "received",
      },
    },
    {
      id: "testimonials",
      title: <FormattedMessage id="Testimonials" />,
      type: "item",
      url: "/testimonials",
      icon: icons.testimonials,
      breadcrumbs: false,
    },

    // {
    //   id: "trainings",
    //   title: <FormattedMessage id="Trainings" />,
    //   type: "item",
    //   url: "/trainings",    
    //   icon: icons.trainings,
    //   breadcrumbs: false,
    // },
    {
      id: "reports",
      title: <FormattedMessage id="Members Reports" />,
      type: "item",
      url: "/reports",
      icon: icons.reports,
      breadcrumbs: false,
    },
    {
      id: "summaryreports",
      title: <FormattedMessage id="Summary Reports" />,
      type: "item",
      url: "/summaryreports",
      icon: icons.reports,
      breadcrumbs: false,
    },

    {
      id: "membergrowth",
      title: <FormattedMessage id="Growth Admin" />,
      type: "item",
      url: "/membergrowth",
      icon: icons.growthadmin,
      breadcrumbs: false,
    },
    
    {
      id: "growthreports",
      title: <FormattedMessage id="Growth Reports" />,
      type: "item",
      url: "/growthreports",
      icon: icons.reports,
      breadcrumbs: false,
    },
  ],
};

export default applications;
